import React, { useEffect, useState, useRef } from 'react';
import LiveCameraSwitch, { ViewType } from './LiveCameraSwitch';
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import { isMobile, isMobileOnly, useMobileOrientation } from 'react-device-detect';
import { Box, CircularProgress, LinearProgress, Typography, useTheme, useMediaQuery } from '@mui/material';
import { isTouchscreen, saveFile } from '../Utilities';
import dayjs from "dayjs"
import PrismaZoom from 'react-prismazoom'
import Title from "../Title"
import Controlbar from '../Controlbar';
import Image from '../Image';

export function LiveViewer(props) {

    const { contentHeight, imageSource, videoSource, cameraName, handleLiveViewSwitch } = props
    const { isLandscape } = useMobileOrientation()
    
    const camCount = window.conf.CAMERAS.length

    return (
        <div 
            className="live-view-container"
            style={{
                display: "flex",
                justifyContent: "center",
                position: "relative"
            }}
        >
            <Title cameraName={cameraName} />
            {imageSource && <LiveCameraSwitch type={ViewType.Image} onClick={handleLiveViewSwitch} />}
            <iframe
                allowFullScreen
                title={cameraName} 
                style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    height: isMobileOnly
                            ? isLandscape
                                ? contentHeight
                                : camCount < 2
                                    ? contentHeight
                                    : camCount < 3
                                        ? contentHeight/2
                                        : 'unset'
                            : contentHeight/Math.ceil(camCount/2),
                    width: "99.5%",
                    border: "none"
                }}
                className={
                    !isMobile
                    ? camCount < 2
                        ? ''
                        : camCount === 2
                            ? 'height-2'
                            : 'height'
                    : ''
                }
                src={videoSource} 
            />
        </div>
    )
}

export function ImageViewer(props) {

    const { breakpoints } = useTheme();
    const { t } = useTranslation()
    const { contentHeight, imageSource, cameraName, hasStream, handleLiveViewSwitch } = props
    const { isLandscape } = useMobileOrientation()
    const { ROUTES: { LIVE }, CAMERAS, LAYOUT: { WRAP_LIMIT, REFRESH_MIN } } = window.conf
    const [zoom, setZoom] = useState(1)
    const [isDownloading, setIsDownloading] = useState(false);
    const fullscreenHandle = useFullScreenHandle()
    const zoomRef = useRef()
    const zoomInFullscreenOnly = isTouchscreen && isMobile;
    const camCount = CAMERAS.length
    const manyCams = camCount > WRAP_LIMIT
    const refresh = REFRESH_MIN * 60000
    const navigate = useNavigate()
    const currentPath = window.location.pathname
    const isXl = useMediaQuery(breakpoints.up('xl'))
    const [imageError, setImageError] = useState(false)

    useEffect(() => {
        resetZoom()
        return () => resetZoom()
    }, [])

    function onZoomChange(zoom) {
        setZoom(zoom)
    }

    function onFullscreenChange() {
        resetZoom()
    }

    function resetZoom() {
        zoomRef?.current?.reset()
    }

    function handleTakeSnapshot() {
        setIsDownloading(true);
        fetch(imageSource)
        .then(res => res.ok ? res.blob() : console.error(res.status, res.statusText))  
        .then(blob => saveFile(blob, (cameraName ? (cameraName + "_") : "") + dayjs().format("YY-MM-DD_HH-mm") + ".jpg"))
        .catch(error => console.error(error))
        .finally(() => setIsDownloading(false))

        // track clicking snapshot
        // if (_etracker && typeof(_etracker) === "object") { // eslint-disable-line no-undef
        //     _etracker.sendEvent(new et_DownloadEvent(`Bildschirmfoto ${cameraName}`, "Bild")) // eslint-disable-line no-undef
        // }
    }

    const containerStyle = {
        style: {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: fullscreenHandle.active
                ? "100%"
                : isMobileOnly
                    ? isLandscape
                        ? contentHeight
                        : camCount < 2
                            ? contentHeight
                            : camCount < 3
                                ? contentHeight/2
                                : 'unset'
                    : manyCams
                        ? 'unset'
                        : isXl && camCount > 2
                            ? contentHeight/Math.ceil(camCount/3)
                            : contentHeight/Math.ceil(camCount/2)
        }
    }

    const Progress = () => {
        return (
            <div style={{
                position: 'absolute',
                width: '99%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                bottom: 35
            }}>
                <Typography variant="caption" fontSize={16} color="white">
                    {t("actions.downloading")}...
                </Typography>
                <LinearProgress style={{ width: '100%' }} />
            </div>
        )
    }

    return (
        <FullScreen 
            handle={fullscreenHandle}
            onChange={onFullscreenChange}
        >
            <Title cameraName={cameraName} />

            {hasStream && 
            <LiveCameraSwitch
                type={ViewType.Streaming} 
                onClick={() => {
                    // If user is not on the live screen then redirect to the live page else switch the view without redirection.
                    if (currentPath !== LIVE.path) {
                        navigate(LIVE.path)
                    } else {
                        handleLiveViewSwitch()
                    }
                }} 
            />}

            {zoomInFullscreenOnly
            ? fullscreenHandle.active
                ? <PrismaZoom
                    allowPan={Boolean(imageSource) && !imageError}
                    allowZoom={Boolean(imageSource) && !imageError}
                    onZoomChange={onZoomChange}
                    ref={zoomRef}
                    {...containerStyle}
                    className={
                        !isMobile
                        ? camCount < 2
                            ? ''
                            : camCount === 2
                                ? 'height-2'
                                : 'height'
                        : ''
                    }
                >
                    <Image 
                        source={imageSource} 
                        cursor={zoom > 1 ? 'move' : 'default'} 
                        refresh={refresh}
                        setImageError={setImageError}
                    />
                    {!imageSource ? <CircularProgress sx={{ position: 'absolute' }} disableShrink size={60} color="secondary" /> : null}
                </PrismaZoom>
                : <Box 
                    {...containerStyle}  
                    className={
                        !isMobile
                        ? camCount < 2
                            ? ''
                            : camCount === 2
                                ? 'height-2'
                                : 'height'
                        : ''
                    }
                >
                    <Image 
                        source={imageSource} 
                        cursor={zoom > 1 ? 'move' : 'default'} 
                        refresh={refresh}
                        setImageError={setImageError}
                    />
                    {!imageSource ? <CircularProgress sx={{ position: 'absolute' }} disableShrink size={60} color="secondary" /> : null}
                </Box>
            : <PrismaZoom
                allowPan={Boolean(imageSource) && !imageError}
                allowZoom={Boolean(imageSource) && !imageError}
                onZoomChange={onZoomChange}
                ref={zoomRef}
                {...containerStyle}
                className= {
                    !isMobile
                    ? camCount < 2
                        ? ''
                        : camCount === 2
                            ? 'height-2'
                            : 'height'
                    : ''
                }
            >
                <Image 
                    source={imageSource} 
                    cursor={zoom > 1 ? 'move' : 'default'} 
                    refresh={refresh}
                    setImageError={setImageError}
                    // doesnt work: fullscreenHandle={fullscreenHandle}
                />
                {!imageSource ? <CircularProgress sx={{ position: 'absolute' }} disableShrink size={60} color="secondary" /> : null}
            </PrismaZoom>}
          
            <Controlbar 
                cameraName={cameraName} 
                currentZoom={zoom} 
                fullscreenHandle={fullscreenHandle} 
                zoomRef={zoomRef}
                zoomInFullscreenOnly={zoomInFullscreenOnly}
                disabled={!imageSource || imageError}
                imageSource={imageSource}
                handleTakeSnapshot={handleTakeSnapshot}
                handleLiveClick={handleLiveViewSwitch}
            />

            {isDownloading && <Progress />}

        </FullScreen>
    )
}
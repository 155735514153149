import dayjs from "dayjs";
import React, { useEffect, useRef, useState } from "react";
import { isMobileOnly, isIOS } from 'react-device-detect';
import { Box, Container, IconButton, Tooltip, Popover } from '@mui/material';
import { FullscreenSharp, FullscreenExitSharp, RemoveCircleOutline, AddCircleOutline, HelpOutline, GridOn, GridOff, CameraAltOutlined } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

export default function Controlbar(props) {

    const { t } = useTranslation()
    const { currentZoom, fullscreenHandle, zoomRef, zoomInFullscreenOnly, disabled, loading, hasOverview, showOverview, handleTakeSnapshot, handleOverviewClick, imageSource } = props

    const [lastModified, setLastModified] = useState(null);
    const [anchorEl, setAnchorEl] = useState(null);
    const [openLastModifiedTooltip, setOpenLastModifiedTooltip] = useState(false);
    
    const zoom = zoomRef?.current
    const refLastModifiedIcon = useRef(null);
    const refLastModifiedPopover = useRef(null);
    const openLastModifiedPopover = Boolean(anchorEl);
    const idLastModifiedPopover = openLastModifiedPopover ? "last-modified-popover" : undefined;
    const lastModifiedFormatted = lastModified ? dayjs(lastModified).format(t("date.format.full_date_time")) : t("msg.no_data")

    // if overview grid is visible than toggle (hide) overview grid, else zoom in
    function onClickOnZoomIn() {
        if (showOverview) {
            handleOverviewClick()
        } else {
            zoom?.zoomIn(1)
        }
    }

    // if current zoom is >100% zoom out, else toggle (open) overview grid
    function onClickOnZoomOut() {
        if (currentZoom > 1) {
            zoom?.zoomOut(1)
        } else {
            handleOverviewClick()
        }
    }

    function onClickLastModifiedButton(event) {
        event.stopPropagation();
        setOpenLastModifiedTooltip(false);
        setAnchorEl(event.currentTarget);
    };
    
    function onCloseLastModifiedPopover() {
        setAnchorEl(null);
    };
    
    useEffect(() => {
        fetch(imageSource, { method: "HEAD" })
        .then(res => {
            const lastModified = res.headers.get("last-modified");
            if (!lastModified) return;
            setLastModified(lastModified);
        })
        .catch((error) => console.error(error));
    }, [imageSource]);

    // check mouse move if leave icon close popover
    useEffect(() => {
        const onLeaveLastModified = (event) => {
            if (!refLastModifiedIcon.current ||
                !refLastModifiedPopover.current ||
                refLastModifiedIcon.current.contains(event.target) ||
                refLastModifiedPopover.current.contains(event.target)) {
                return;
            }
            onCloseLastModifiedPopover();
        };
        document.addEventListener("mousemove", onLeaveLastModified);
        return () => document.removeEventListener("mousemove", onLeaveLastModified);
    }, []);

    return (
        <Container className={isMobileOnly || loading ? "Controlbar-mobileFooter" : "Controlbar-footer"}>
            <Box 
                className="Controlbar-wrapper" 
                sx={{ justifyContent: zoomInFullscreenOnly && !fullscreenHandle.active ? "end" : "center" }}
            >
            
               <div style={{
                    display: 'flex',
                    width: '100%'
                }}>
                    
                    <div className='control-item'>{/* just for spacing */}</div>

                    <div className='control-item'>
                        {zoomInFullscreenOnly && !fullscreenHandle.active 
                        ? null 
                        : <Box>
                            <Tooltip 
                                title={t("actions.zoom_out")} 
                                arrow
                                componentsProps={{
                                    popper: {
                                        disablePortal: true,
                                    },
                                }}
                            >
                                <div style={{display: 'inline-block'}}>
                                    <IconButton 
                                        className="Controlbar-iconbutton" 
                                        aria-label="zoom out" 
                                        onClick={onClickOnZoomOut}
                                        disabled={(disabled || (currentZoom === 1 && showOverview)) || (!hasOverview && currentZoom === 1)}
                                    >
                                        {(disabled || (currentZoom === 1 && showOverview)) || (!hasOverview && currentZoom === 1)
                                        ? <RemoveCircleOutline sx={{ color: "#606060", fontSize: "1.5rem" }} />
                                        : <RemoveCircleOutline sx={{ color: "white", fontSize: "1.5rem" }} />}
                                    </IconButton>
                                </div>
                            </Tooltip>
                            
                            <span 
                                className="Controlbar-label"
                                style={{ color: disabled ? "#606060" : "white" }}
                            >
                                {showOverview ? 10 : parseInt(currentZoom * 100)}%
                            </span>

                            <Tooltip 
                                title={t("actions.zoom_in")} 
                                arrow
                                componentsProps={{
                                    popper: {
                                        disablePortal: true,
                                    },
                                }}
                            >
                                <div style={{display: 'inline-block'}}>
                                    <IconButton 
                                        className="Controlbar-iconbutton" 
                                        aria-label="zoom in" 
                                        onClick={onClickOnZoomIn}
                                        disabled={currentZoom === 5 || disabled}
                                    >
                                        {currentZoom === 5 || disabled
                                        ? <AddCircleOutline sx={{ color: '#606060', fontSize: "1.5rem" }} />
                                        : <AddCircleOutline sx={{ color: 'white', fontSize: "1.5rem" }} />}
                                    </IconButton>
                                </div>
                            </Tooltip>
                        </Box>}
                    </div>

                    <div className='control-item'>
                        
                        {hasOverview &&
                        <Tooltip 
                            title={t("actions.overview")} 
                            arrow
                            componentsProps={{
                                popper: {
                                    disablePortal: true,
                                },
                            }}
                        > 
                            <span>
                                <IconButton 
                                    className="Controlbar-iconbutton" 
                                    aria-label="overview" 
                                    onClick={handleOverviewClick}
                                    disabled={disabled}
                                >   
                                    {disabled 
                                    ? showOverview
                                        ? <GridOff sx={{ color: '#606060', fontSize: "1rem" }} />
                                        : <GridOn sx={{ color: '#606060', fontSize: "1rem" }} />
                                    : showOverview
                                        ? <GridOff sx={{ color: 'white', fontSize: "1rem" }} />
                                        : <GridOn sx={{ color: 'white', fontSize: "1rem" }} />}
                                </IconButton>
                            </span>
                        </Tooltip>}

                        <Tooltip
                            open={openLastModifiedTooltip}
                            onClose={() => setOpenLastModifiedTooltip(false)}
                            onOpen={() => !openLastModifiedPopover && setOpenLastModifiedTooltip(true)}
                            title={t("msg.last_update")}
                            arrow
                            componentsProps={{
                                popper: {
                                    disablePortal: true,
                                },
                            }}
                        >
                            <span>
                                <IconButton
                                    ref={refLastModifiedIcon}
                                    className="Controlbar-iconbutton"
                                    aria-label="help-outline"
                                    disabled={disabled || showOverview}
                                    onClick={onClickLastModifiedButton}
                                    sx={{ ...(openLastModifiedPopover && { zIndex: 99999}) }}
                                >

                                    {disabled || showOverview 
                                    ? <HelpOutline sx={{ color: "#606060", fontSize: "1.1rem" }} />
                                    : <HelpOutline sx={{ color: "white", fontSize: "1.1rem" }} />}

                                </IconButton>

                                <Popover
                                    id={idLastModifiedPopover}
                                    open={openLastModifiedPopover}
                                    disablePortal
                                    anchorEl={anchorEl}
                                    onClose={onCloseLastModifiedPopover}
                                    onMouseLeave={onCloseLastModifiedPopover}
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'center'
                                    }}
                                >
                                    <Box
                                        ref={refLastModifiedPopover}
                                        sx={{
                                            p: 1,
                                            maxWidth: "300px",
                                            overflowWrap: "break-word",
                                        }}
                                    >
                                        {`${t("msg.last_update")}: ${lastModifiedFormatted}`}
                                    </Box>
                                </Popover>
                            </span>
                        </Tooltip>

                        <Tooltip 
                            title={t("actions.screenshot")} 
                            arrow
                            componentsProps={{
                                popper: {
                                    disablePortal: true,
                                },
                            }}
                        > 
                            <span>
                                <IconButton 
                                    className="Controlbar-iconbutton" 
                                    aria-label="snapshot" 
                                    onClick={handleTakeSnapshot}
                                    disabled={disabled || showOverview}
                                >   
                                    {disabled || showOverview
                                    ? <CameraAltOutlined sx={{ color: '#606060', fontSize: "1.2rem", mt: "1px" }} />
                                    : <CameraAltOutlined sx={{ color: 'white', fontSize: "1.2rem", mt: "1px" }} />}
                                </IconButton>
                            </span>
                        </Tooltip>

                        {!isIOS &&
                        <Tooltip 
                            title={t("actions.fullscreen")} 
                            arrow
                            componentsProps={{
                                popper: {
                                    disablePortal: true,
                                },
                            }}
                        >
                            <span>
                                <IconButton 
                                    className="Controlbar-iconbutton" 
                                    aria-label="fullscreen" 
                                    onClick={fullscreenHandle.active ? fullscreenHandle.exit : fullscreenHandle.enter}
                                    disabled={disabled || showOverview}
                                >
                                    {disabled || showOverview 
                                    ? fullscreenHandle.active
                                        ? <FullscreenExitSharp sx={{ color: '#606060', fontSize: "1.5rem", mr: "5px" }} />
                                        : <FullscreenSharp sx={{ color: '#606060', fontSize: "1.5rem", mr: "5px" }} />
                                    : fullscreenHandle.active
                                        ? <FullscreenExitSharp sx={{ color: 'white', fontSize: "1.5rem", mr: "5px" }} />
                                        : <FullscreenSharp sx={{ color: 'white', fontSize: "1.5rem", mr: "5px" }} />}
                                </IconButton>
                            </span>
                        </Tooltip>}
                
                    </div>
                </div>
            </Box>
        </Container>
    )
}
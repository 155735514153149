import { PaletteOutlined } from '@mui/icons-material';
import { IconButton, Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';

export default function Color({ invertColors, onClick }) {

    const { t } = useTranslation()

    return (
        <Tooltip title={t("color.scheme")} arrow>
            <IconButton
                sx={{ 
                    p: 0,
                    pr: { xs: '12px', md: 0 },
                    pl: { xs: 0, md: '12px' },
                    color: invertColors ? 'white' : 'primary.main'
                }}
                onClick={onClick}
            >
                <PaletteOutlined sx={{ fontSize: "1rem" }} />
            </IconButton>
        </Tooltip>
    )
}
import { useEffect, useState } from "react";
import { Box, Button, Typography } from '@mui/material';
import { useAppBarHeight } from './Utilities';
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { useKeycloak } from './Keycloak';

export default function Status({ code, controls }) {

    const [windowHeight, setWindowHeight] = useState(window.innerHeight * window.visualViewport?.scale || 1)

    const { HOME } = window.conf.ROUTES
    const { t } = useTranslation()
    const { doLogout } = useKeycloak()
    
    const backHome = () => navigate(HOME.path)
    const hasControl = (control) => controls?.includes(control)
    const updateWindowDimensions = () => setWindowHeight(window.innerHeight * window.visualViewport?.scale || 1)
    const navigate = useNavigate()
    const appbarHeight = useAppBarHeight()
    const contentHeight = hasControl("header") ? windowHeight - appbarHeight : windowHeight

    useEffect(() => {
        window.addEventListener('resize', updateWindowDimensions)
        return () => window.removeEventListener('resize', updateWindowDimensions)
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
                height: contentHeight
            }}
        >

            <Typography 
                variant="h1" 
                style={{ 
                    color: 'white',
                    userSelect: 'none'
                }}
            >
                {t("status.sorry")}
            </Typography>

            <Typography 
                variant="h6" 
                style= {{ 
                    color: 'white', 
                    maxWidth: '80%', 
                    textAlign: 'center',
                    userSelect: 'none'
                }}
            >
                {t("status." + code)}
            </Typography>

            {hasControl("back") &&
            <Button 
                variant="contained" 
                style={{ marginTop: "20px" }} 
                onClick={backHome}
            >
                {t("status.back_home")}
            </Button>}

            {hasControl("logout") &&
            <Button 
                variant="contained" 
                style={{ marginTop: "20px" }} 
                onClick={doLogout}
            >
                {t("user.logout")}
            </Button>}
            
        </Box>
    )
}
import 'video.js/dist/video-js.css';
import videojs from "video.js";
import de from 'video.js/dist/lang/de.json';
import nl from 'video.js/dist/lang/nl.json';
import i18next from "i18next";
import { LinearProgress, Typography } from "@mui/material";
import { useEffect, useRef, useState, useCallback } from "react";
import { saveFile } from "../Utilities";
import { useTranslation } from "react-i18next";
import { isMobile } from 'react-device-detect';

const VideoJs = (props) => {

    const { t } = useTranslation()
    const { source, cameraName } = props
    const { CAMERAS, LAYOUT: { WRAP_LIMIT } } = window.conf

    const [isDownloading, setIsDownloading] = useState(false)

    const videoRef = useRef(null)
    const playerRef = useRef(null)
    const manyCams = CAMERAS.length > WRAP_LIMIT

    const downloadVideo = useCallback((url) => {
        setIsDownloading(true);
        fetch(url)
        .then(res => res.ok ? res.blob() : console.error(res.status, res.statusText))  
        .then(blob => saveFile(blob, `${cameraName ? (cameraName + "_") : ""}${t("pages.timelapse")}.mp4`))
        .catch(error => console.error(error))
        .finally(() => setIsDownloading(false))
    }, [cameraName, t])

    const takeSnapshot = useCallback((video)=> {
        const canvas = document.createElement('canvas')

        canvas.width = video.videoWidth
        canvas.height = video.videoHeight

        const ctx = canvas.getContext('2d')
        ctx.drawImage(video, 0, 0, canvas.width, canvas.height)

        const image = canvas.toDataURL('image/jpeg', 1.0)

        saveFile(image, `${cameraName ? (cameraName + "_") : ""}${t("actions.screenshot")}.jpg`) 
    }, [cameraName, t])

    useEffect(() => {

        if (!playerRef.current) {

            const videoElement = videoRef.current

            if (!videoElement) return

            if (i18next.language !== 'en') {
                videojs.addLanguage('de', de)
                videojs.addLanguage('nl', nl)
                // add more languages here
            }

            const options = {
                autoplay: false,
                language: i18next.language,
                controls: true,
                responsive: true,
                preload: isMobile ? "metadata" : "auto",
                nativeControlsForTouch: true,
                sources: [{ src: `${source}` }],
                playbackRates: [ 0.5, 1, 1.5, 2 ],
                controlBar:{ 'volumePanel': false }
            }

            playerRef.current = videojs(videoElement, options)
            const playerControlBar = playerRef.current.getChild('ControlBar')
            playerControlBar?.classList?.add('vjs-controll-bar-bottom')

            const downloadButtonOptions = {
                controlText: `${t("actions.download")}`,
                className: 'vjs-download-button',
                clickHandler: () => downloadVideo(source)
            }

            const snapshotButtonOptions = {
                controlText: `${t("actions.screenshot")}`,
                className: 'vjs-snapshot-button',
                clickHandler: () => takeSnapshot(videoElement)
            }
            
            playerControlBar?.addChild('button', snapshotButtonOptions, 16)
            playerControlBar?.addChild('button', downloadButtonOptions)
        }
    }, [source, videoRef, downloadVideo, t]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        const player = playerRef.current

        return () => {
            if (player) {
                player.dispose()
                playerRef.current = null
            }
        };
    }, [playerRef])

    const Progress = () => {
        return (
            <div style={{
                position: 'absolute',
                width: '99%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                bottom: 35
            }}>
                <Typography variant="caption" fontSize={16} color="white">
                    {t("actions.downloading")}...
                </Typography>
                <LinearProgress style={{ width: '100%' }} />
            </div>
        )
    }

    return (
        <div style={{
            width: "100%",
            height: manyCams ? "98%" : '100%',
            display: 'flex',
            alignItems: 'center',
        }}>
            <video
                ref={videoRef}
                className="video-js vjs-big-play-centered vjs-full-height"
                crossOrigin="anonymous" // needed for snapshot on local environment
            />
            {isDownloading && <Progress />}
        </div>
    );
}

export default VideoJs;
import React, { useEffect, useState, Fragment } from 'react';
import { databaseDateToFilename, useAppBarHeight, useStatistics } from "./Utilities"
import { ImageViewer } from "./live/Viewer"
import { isMobile, useMobileOrientation } from 'react-device-detect';
import { getData } from '../api/dataSlice';
import { useSelector } from "react-redux"
import Grid from '@mui/material/Grid'
import TimelapseViewer from "./timelapse/Viewer"
import ArchiveViewer from "./archive/Viewer"
import ControlsArchive from './archive/Controls'
import ImageWithLiveView from './live/ImageWithLiveView';
import ControlsTimelapse from './timelapse/Controls';

export default function Content({ component }) {

    const { CAMERAS, LAYOUT: { WRAP_LIMIT, TIMELAPSE }, DEBUG } = window.conf
    const { isPortrait } = useMobileOrientation()
    const { index, source, initialFooterHeight } = useSelector(getData)

    const [windowHeight, setWindowHeight] = useState(window.innerHeight * window.visualViewport?.scale || 1)
    const [isDrawerOpen, setIsDrawerOpen] = useState(false)
    const [paddingFooter, setPaddingFooter] = useState(0)
    
    const appbarHeight = useAppBarHeight()
    const contentHeight = windowHeight - appbarHeight
    const initialSiderWidth = 332
    const camCount = CAMERAS.length
    const manyCameras = camCount > WRAP_LIMIT
    const timelapseSider = !!TIMELAPSE
    const hasControls = timelapseSider ? 
        (component === 2 || component === 3) :
        component === 3
    const updateWindowDimensions = () => setWindowHeight(window.innerHeight * window.visualViewport?.scale || 1)
    const isStatisticsLoaded = useStatistics()

    useEffect(() => {
        isStatisticsLoaded && DEBUG && console.log("[etracker] Successfully initialized")
    }, [isStatisticsLoaded]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        window.addEventListener('resize', updateWindowDimensions)
        return () => window.removeEventListener('resize', updateWindowDimensions)
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    // 0 - HOME, 1 - LIVE, 2 - TIMELAPSE, 3 - MY SITE
    function renderComponent(camera) { 
        switch (component) {
            case 0:
                return (
                    <ImageViewer
                        contentHeight={contentHeight} 
                        imageSource={camera.image} 
                        cameraName={camera.name}
                        hasStream={camera.video}
                    />
                )
            case 1:
                // If camera contains streaming video then render the ImageWithLiveView to switch between the live and image modes
                if (camera.video) {
                    return (
                        <ImageWithLiveView
                            contentHeight={contentHeight}
                            camera={camera}
                        />
                    )
                } else {
                    return (
                        <ImageViewer
                            contentHeight={contentHeight} 
                            imageSource={camera.image} 
                            cameraName={camera.name}
                        />
                    )
                }
            case 2:
                return (
                    <TimelapseViewer
                        contentHeight={timelapseSider ? (isMobile && isPortrait ? contentHeight - initialFooterHeight : contentHeight) : contentHeight}
                        videoSource={camera.timelapse} 
                        cameraName={camera.name}
                    />
                )
            case 3:
                var url = camera.image
                var imageLoading = true

                if (source && Number.isFinite(index)) {
                    url = camera.download + databaseDateToFilename(source[0]?.[index]?.[1]?.[camera.id]?.datum)
                    imageLoading = false
                }

                return (
                    <ArchiveViewer
                        contentHeight={isMobile && isPortrait ? contentHeight - initialFooterHeight : contentHeight} 
                        cameraName={camera.name}
                        cameraId={camera.id}
                        imageSource={url}
                        imageIndex={index}
                        imagesList={source ? source : []}
                        cameraUrl = {camera.download}
                        imageLoading={imageLoading}
                        isDrawerOpen={isDrawerOpen}
                        paddingFooter={paddingFooter}
                    />
                )
            default:
                break;
        }
    }

    return (
        <Fragment>
            <Grid 
                container
                columns={{ 
                    xs: 1, 
                    md: 2,
                    lg: manyCameras ? 3 : 2, 
                    xl: manyCameras ? 
                        4 : 
                        camCount > 2 ?
                            3 :
                            2
                }}
                sx={{ 
                    marginBottom: hasControls && isMobile && isPortrait ? `${initialFooterHeight}px` : 0,
                    width: hasControls
                            ? isMobile 
                                ? !isPortrait 
                                    ? !isDrawerOpen
                                        ? `calc(100% - ${20}px)` 
                                        : `calc(100% - ${initialSiderWidth}px)`
                                    : '100%'
                                : `calc(100% - ${initialSiderWidth}px)`
                            : '100%',
                    transition: '0.2s linear',
                    background: "black",
                    overflowX: "hidden",
                    paddingBottom: hasControls && isMobile && isPortrait ? `${paddingFooter}px` : 0,
                }}
            >
                {CAMERAS.map((camera, index) => (
                    <Grid 
                        item 
                        key={index}
                        xs={1}
                        md={camCount % 2 // amount of images is odd (e.g. 1, 3 or 5)
                            && index === camCount-1 // image is last element of the array
                            && !manyCameras
                            ? 2 // extend over both columns
                            : 1
                        }
                        xl={camCount > 2 ? 1 : undefined}
                        sx={{ 
                            backgroundColor: "black", // TODO: change background color of images/video
                            overflow: "hidden"
                        }}
                    >
                        {renderComponent(camera)}
                    </Grid>
                ))}   
            </Grid>

            {/* Timelapse has Controls */}

            {component === 2 &&
            timelapseSider &&
            <ControlsTimelapse
                initialSiderWidth={initialSiderWidth}
                onOpenSideDrawer={setIsDrawerOpen}
                setPaddingFooter={setPaddingFooter}
            />}

            {/* Archive has Controls */}

            {component === 3 &&
            <ControlsArchive
                initialSiderWidth={initialSiderWidth}
                onOpenSideDrawer={setIsDrawerOpen}
                setPaddingFooter={setPaddingFooter}
            />}

        </Fragment>
    )
}
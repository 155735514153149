import { Copyright as CopyrightIcon } from '@mui/icons-material';
import { Divider, Grid, IconButton, Link, Popover, Stack, Typography, Tooltip } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import NetCoLogo from '../../assets/logo_netco_bau_tv.png';

export default function Copyright({ invertColors }) {

    const { t } = useTranslation()
    const [anchorEl, setAnchorEl] = useState(null)

    const handleOpen = (event) => {
        if (anchorEl !== event?.currentTarget) {
            setAnchorEl(event.currentTarget)
        }
    }

    const handleClose = () => {
        if (anchorEl) {
            setAnchorEl(null)
        }
    }

    return (
        <>
            <Tooltip title={t("copyright.copyright")} arrow>
                <IconButton
                    sx={{ 
                        p: 0,
                        pr: { xs: '12px', md: 0 },
                        pl: { xs: 0, md: '12px' },
                        color: invertColors ? 'white' : 'primary.main'
                    }}
                    onClick={handleOpen}
                >
                    <CopyrightIcon sx={{ fontSize: "1rem" }} />
                </IconButton>
            </Tooltip>

            <Popover
                id={Boolean(anchorEl) ? 'copyright-popover' : undefined}
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center'
                }}
            >
                <Details />
            </Popover>
        </>
    )
}

function Details() {
    const { t } = useTranslation()
    return (
        <Grid
            container
            rowSpacing={1.6}
            direction="column"
            sx={{
                textAlign: "center",
                p: 2,
                maxWidth: "280px",
                userSelect: "none"
            }}
        >
            <Grid
                item
                xs={12}
            >
                <Stack
                    spacing={1}
                    direction="column"
                    sx={{
                        alignItems: "center"
                    }}
                >
                    <img
                        style={{ width: 172 }}
                        src={NetCoLogo}
                        alt="NetCo Logo"
                    />
                    <Typography sx={{ fontSize: "1rem", pb: 1 }}>
                        {t("copyright.title")}
                    </Typography>
                </Stack>
                <Divider />
            </Grid>

            <Grid
                item
                xs={12}
                sx={{ p: 0, m: 0 }}
            >
                <Link href='https://www.kamera.netco.de/datenschutz/' underline="none" target='_blank'>
                    <Typography sx={{ fontSize: "1rem", pb: 1 }}>
                        {t("copyright.privacy")}
                    </Typography>
                </Link>
                <Divider />
            </Grid>

            <Grid
                item
                xs={12}
            >
                <Link href='https://www.kamera.netco.de/impressum/' underline="none" target='_blank'>
                    <Typography sx={{ fontSize: "1rem", pb: 1 }}>
                        {t("copyright.print")}
                    </Typography>
                </Link>
                <Divider />
            </Grid>


            <Grid
                item
                xs={12}
            >
                <Link href='https://www.baustellen-webcams.de/' underline="none" target='_blank'>
                    <Typography sx={{ fontSize: "1rem" }}>
                        baustellen-webcams.de
                    </Typography>
                </Link>
            </Grid>
        </Grid>
    )
}
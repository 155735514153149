import { useState } from "react";
import { ImageViewer, LiveViewer } from "./Viewer";

export default function ImageWithLiveView({ contentHeight, camera }) {
    const [showLive, setShowLive] = useState(true);

    const handleLiveViewSwitch = () => {
        setShowLive(!showLive)
    }

    return <>
        {showLive 
        ? <LiveViewer
            contentHeight={contentHeight}
            videoSource={camera.video}
            imageSource={camera.image}
            cameraName={camera.name}
            handleLiveViewSwitch={handleLiveViewSwitch}
        />
        : <ImageViewer
            contentHeight={contentHeight}
            imageSource={camera.image}
            cameraName={camera.name}
            hasStream={camera.video}
            handleLiveViewSwitch={handleLiveViewSwitch}
        />}
    </>
}
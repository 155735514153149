import dayjs from "dayjs"
import scale from "../../assets/scale-450p.jpg"
import { SellOutlined } from "@mui/icons-material"
import { Checkbox, Grid, Typography } from "@mui/material"
import { useState, useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { getData, setCheckedArchivePictures } from "../../api/dataSlice"
import { databaseDateToFilename } from "../Utilities"

const OverviewImage = ({ imageIndex, isSelected, isTagged, timestamp, index, onImageSelected, cameraUrl, archivePicture }) => {

    const dispatch = useDispatch()
    const filename = databaseDateToFilename(archivePicture.datum)

    const { t } = useTranslation()
    const { checkedArchivePictures } = useSelector(getData)
    
    const [isChecked, setIsChecked] = useState(false)
    const [selected, setSelected] = useState(isSelected) // record selected status so previous status can be known when needed
    const [src, setSrc] = useState(`${cameraUrl + filename}`)

    function onErrorImgSrc() {
        setSrc(scale)
    }
    
    useEffect(()=> {

        let previouslySelected = selected // this variable will be use to get previous status of image if it was selected or not

        setSelected(prevState => {
            if (prevState) {    // check if previously selected
                previouslySelected = true // set to true bcz previously selected state was true
            }
            return isSelected // after getting record of prevstate set it again to current status
        })

        if (isSelected) { 

            setIsChecked(true) // set image as checked if image is selected as default
            
            if (checkedArchivePictures.findIndex(obj => obj.archivePictureId === archivePicture.id) === -1) {   
                dispatch(
                    setCheckedArchivePictures({
                        type: 'add',
                        action: { 
                            value: {
                                archivePictureId: archivePicture.id,
                                timestamp: timestamp
                            },
                        }
                    })
                )
            }
            
                
        } else if (!isChecked) {

            setIsChecked(false) // uncheck if image is not selectedImage

        } else if (previouslySelected) { // if it was privously selected then remove and uncheck
            
            const index = checkedArchivePictures.findIndex(obj => obj.archivePictureId === archivePicture.id) // index to remove 
               
                dispatch(
                    setCheckedArchivePictures({ // removing from store 
                        type: 'remove',
                        action: { 
                            value: {
                                archivePictureId: archivePicture.id,
                                timestamp: timestamp
                            },
                            index: index 
                        }
                    })
                )

                setIsChecked(false) // uncheck 
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSelected, imageIndex])

    function handleCheckedImg(event) {

        const archivePictureId = Number(event.target.value)
        const checkedIndex = checkedArchivePictures.findIndex(obj => obj.archivePictureId === archivePictureId)

        if (checkedIndex === -1) { // add timestamp to checkedArchivePictures if does not exist
            dispatch(
                setCheckedArchivePictures({
                    type: 'add',
                    action: { 
                        value: {
                            archivePictureId: archivePicture.id,
                            timestamp: timestamp
                        },
                    }
                })
            )

            setIsChecked(true)

        } else { // remove timestamp from checkedArchivePictures if exists

            dispatch(
                setCheckedArchivePictures({
                    type: 'remove',
                    action: {
                        value: {
                            archivePictureId: archivePicture.id,
                            timestamp: timestamp
                        },
                        index: checkedIndex
                    }
                })
            )

            setIsChecked(false)
        } 
    }

    const styles={
        imageContainer: {
            width: '100%',
            backgroundColor: 'white',
            borderBottomLeftRadius: 6,
            borderBottomRightRadius: 6,
            borderTopLeftRadius: 0,
            borderTopRightRadius: 0,
            overflowX: "hidden"
        },
        selectedImageContainer: {
            borderBottomLeftRadius: 6,
            borderBottomRightRadius: 6,
            borderTopLeftRadius: 0,
            borderTopRightRadius: 0,
            backgroundColor: "#003366",
            color: "white",
            paddingLeft: 4,
            paddingRight: 4,
            paddingTop: 4,
            overflowX: "hidden"
        }, 
        image: {
            display: "flex",
            width: "100%",
            objectFit: "cover",
            cursor: isSelected ? "default" : "pointer"
        }
    }

    return (
        <Grid item xs={2} sm={4} md={4} key={`overview-grid-item-${index}`}>
            <div
                id={`grid-image-${index}`}
                style={isSelected ? styles.selectedImageContainer : styles.imageContainer}
            >
                <img
                    onClick={() => onImageSelected(index, true)}
                    key={`grid-image-${index}`}
                    src={src}
                    alt={filename}
                    style={styles.image}
                    loading="lazy"
                    onError={onErrorImgSrc}
                />
                <Typography 
                    variant='body1' 
                    align="center" 
                    sx={{ 
                        display: "flex", 
                        justifyContent: "center",
                        alignItems: "center",
                        cursor: "default",
                        userSelect: "none"
                    }}
                >
                    <Checkbox 
                        value={archivePicture.id ? archivePicture.id : 0}
                        onChange={handleCheckedImg}
                        checked={isChecked}
                        disabled={isSelected}
                        size="small"
                        sx={{
                            '&.MuiSvgIcon-root': { width: '20px', height: "20px" },
                            '&.Mui-disabled': { color: '#626364' },
                            p: 0,
                            pr: '6px'
                        }} 
                    />
                    {dayjs(timestamp).format(t("date.format.full_date_time"))}
                    {isTagged && 
                    <SellOutlined sx={{ 
                        fontSize: '15px',
                        pl: '4px'
                    }}/>}
                </Typography>
            </div>
        </Grid >
    )
}

export default OverviewImage
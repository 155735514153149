import { useState, Fragment } from "react";
import { IconButton, Menu, MenuItem, Tooltip, Divider, ListItemIcon } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Logout, PortraitOutlined } from '@mui/icons-material';
import { useKeycloak } from '../Keycloak';
import { useNavigate } from "react-router-dom";

export default function User({ invertColors, pages }) {

    const [anchorEl, setAnchorEl] = useState(null)
    
    const { t, i18n } = useTranslation()
    const { doLogout, isLoggedIn } = useKeycloak()
    const { HOME } = window.conf.ROUTES

    const navigate = useNavigate()
    const curLang = i18n.language

    const handleOpen = (event) => {
        if (anchorEl !== event?.currentTarget) {
            setAnchorEl(event.currentTarget)
        }
    }

    const changeLanguage = (ln) => {
        handleClose()
        i18n.changeLanguage(ln)
        window.localStorage.setItem('netco_lang', ln);
    }

    const handleClose = () => {
        if (anchorEl) {
            setAnchorEl(null)
        }
    }

    const handleLogout = () => {
        navigate(HOME.path)
        handleClose()
        doLogout()
    }

    return (
        <Fragment>
            <Tooltip title={t("user.settings")} arrow>
                <IconButton
                    sx={{ 
                        p: 0,
                        pr: { xs: '12px', md: 0 },
                        pl: { xs: 0, md: '12px' },
                        color: invertColors ? 'white' : 'primary.main'
                    }}
                    onClick={handleOpen}
                >
                    <PortraitOutlined sx={{ fontSize: "1.111111rem" }} />
                </IconButton>
            </Tooltip>

            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center'
                }}
            >

                <MenuItem 
                    onClick={() => changeLanguage('de')} 
                    sx={{ backgroundColor: curLang === 'de' ? '#e0e6ec' : 'white' }}
                    selected={curLang === 'de'}
                >
                    <ListItemIcon>
                        <div className="flag flag_de"></div>
                    </ListItemIcon>
                    {t(`user.language.german`)}
                </MenuItem>

                <MenuItem 
                    onClick={() => changeLanguage('en')} 
                    sx={{ backgroundColor: curLang === 'en' ? '#e0e6ec' : 'white' }}
                    selected={curLang === 'en'}
                >
                    <ListItemIcon>
                        <div className="flag flag_en"></div>
                    </ListItemIcon>
                    {t(`user.language.english`)}
                </MenuItem>

                <MenuItem 
                    onClick={() => changeLanguage('nl')} 
                    sx={{ backgroundColor: curLang === 'nl' ? '#e0e6ec' : 'white' }}
                    selected={curLang === 'nl'}
                >
                    <ListItemIcon>
                        <div className="flag flag_nl"></div>
                    </ListItemIcon>
                    {t(`user.language.dutch`)}
                </MenuItem>

                <Divider />

                <MenuItem onClick={handleLogout} disabled={!isLoggedIn()}>
                    <ListItemIcon>
                        <Logout fontSize="small" />
                    </ListItemIcon>
                    {t(`user.logout`)}
                </MenuItem>

            </Menu>
        </Fragment>
    )
}
import dayjs from "dayjs"
import { useState, useLayoutEffect, useEffect } from 'react';
import { useMediaQuery, useTheme } from '@mui/material';

const { DEBUG, KEYCLOAK: { CLIENT_ID } } = window.conf

export function useAppBarHeight() {
    const { mixins: { toolbar }, breakpoints } = useTheme();
    const toolbarDesktopQuery = breakpoints.up('sm');
    const toolbarLandscapeQuery = `${breakpoints.up('xs')} and (orientation: landscape)`;
    const isDesktop = useMediaQuery(toolbarDesktopQuery);
    const isLandscape = useMediaQuery(toolbarLandscapeQuery);

    if (isDesktop) {
        return toolbar[toolbarDesktopQuery ].minHeight;
    } else if (isLandscape) {
        return toolbar[toolbarLandscapeQuery].minHeight;
    } else {
        return toolbar.minHeight;
    }
}

export function isTouchscreen() {
    if (window.PointerEvent && ("maxTouchPoints" in navigator)) { // if Pointer Events are supported, check maxTouchPoints
        if (navigator.maxTouchPoints > 0)
            return true;
    } else {
        if (window.matchMedia && window.matchMedia("(any-pointer:coarse)").matches) { // any-pointer:coarse mostly means touchscreen
            return true;
        } else if (window.TouchEvent || ("ontouchstart" in window)) { // exposed touch events API/event handler
            return true;
        }
    }
    return false;
}

export function approachNumber(input, goal) {
    return input?.length ? input.reduce((prev, curr) => (Math.abs(curr-goal) < Math.abs(prev-goal) ? curr : prev)) : null
}

export function getAdjustedIndexAndTimestamp(source, date) {
    
    var timestamp = dayjs(date)
    var indexAndTimestamp = {}

    if (source && timestamp) {
        var list = source[0]
        var dictByMinute = source[1]
        var dictByHour = {}
        var indexes = [timestamp.$y, timestamp.$M, timestamp.$D, timestamp.$H] // split timestamp in parts
        
        indexAndTimestamp = {
            index: list?.length-1,
            timestamp: timestamp,
        };

        // iterate through dictionary
        for (let i=0; i<indexes.length; i++) {
            const elem = indexes[i]
            if (dictByMinute?.[elem]) {
                dictByMinute = dictByMinute[elem]
            }
        }

        dictByHour = dictByMinute

        if (dictByMinute?.[timestamp.$m]) {
            dictByMinute = dictByMinute[timestamp.$m]
        }

        var newIndex = dictByMinute?.[timestamp.$m]

        // check if minute of timestamp is an available key of the dictionary, otherwise search for prev/next minute in keys
        if (Number.isFinite(newIndex)) {
            newIndex = dictByMinute?.[timestamp.$m]
            indexAndTimestamp.index = newIndex;
            indexAndTimestamp.timestamp = timestamp;
        } else {
            let minutes = Object.keys(dictByMinute)
            let goalMinute = timestamp.$m
            let closestMinute = approachNumber(minutes, goalMinute)

            newIndex = dictByMinute?.[closestMinute]?.[closestMinute]
            
            // search for prev/next hour in keys
            if (Number.isFinite(newIndex)) {
                indexAndTimestamp.timestamp = dayjs(list?.[newIndex]?.[0]);
                indexAndTimestamp.index = newIndex;
            } else {
                let hours = Object.keys(dictByHour ?? null)
                let goalHour = timestamp.$H
                let closestHour = approachNumber(hours, goalHour)
                // let newMinutes = Object.keys(dictByHour?.[closestHour]) // old
                let newMinutes = dictByHour?.[closestHour] ? Object.keys(dictByHour?.[closestHour]) : null
                let newMinute = approachNumber(newMinutes, goalMinute)
                
                newIndex = dictByHour?.[closestHour]?.[newMinute]?.[newMinute]

                if (Number.isFinite(newIndex)) {
                    indexAndTimestamp.index = newIndex;
                    indexAndTimestamp.timestamp = dayjs(list?.[newIndex]?.[0]);
                } else {
                    console.error("timestamp invalid, index not existing", newIndex)
                }
            }
        }
    }

    return indexAndTimestamp;
}

export const isBlob = input => 'Blob' in window && input instanceof Blob

export const saveFile = (file, name) => {
    const a = document.createElement('a')
    a.download = name
    if (isBlob(file)) {
        a.href = URL.createObjectURL(file)
        a.addEventListener('click', () => setTimeout(() => URL.revokeObjectURL(a.href), 30 * 1000))
    } else {
        a.href = file
    }
    a.click()
    a.remove()
}

// see: https://web.archive.org/web/20160509220835/http://blog.abouthalf.com/development/orientation-media-query-challenges-in-android-browsers/
export const IsPortrait = () => useMediaQuery('(max-aspect-ratio: 13/9)')
export const IsLandscape = () => useMediaQuery('(min-aspect-ratio: 13/9)')

export const generateTuples = (imageStreams) => {

    if (DEBUG) {
        console.time("generateTuples")
    }
    
    var list = []
    var listIndex = 0
    var dict = {}

    // input is a list of n reverted lists of filenames of cameras
    if (Array.isArray(imageStreams)) {

        // execute at least once
        var runAgain = true

        // last valid element
        var dummys = []

        // as long as an array still has elements
        while (runAgain) { // slower: imageStreams.some(array => array.length)
            runAgain = false

            // get last element of each list
            var firstImages = [] // slower: imageStreams.map(array => array.length ? array[0] : null)

            for (let i = 0; i < imageStreams.length; i++) {
                var array = imageStreams[i]

                if (array?.length) {
                    firstImages[i] = array[array.length-1] // slower: firstImages.push(array[array.length-1])

                    // as long as an list still has elements
                    runAgain = true
                } else {
                    firstImages[i] = null // slower: firstImages.push(null)
                }
            }

            // finished when list has no more elements
            if (!runAgain) {
                break
            }

            // get minimum of the elements from first elements without null
            var min = findMin(firstImages.filter(elem => elem))

            // build element for result
            var elemKey = null
            var elemTuple = []
            var date

            for (let i = 0; i < firstImages.length; i++) {

                // check if one of the elements equals minimum
                // if (firstImages[i].datum === min) { // old
                if (firstImages[i]?.datum === min) {

                    // remove last element of a list when its first element was minimum
                    imageStreams[i].pop()

                    // use first valid element in tuple as key for each element
                    date = databaseDateToObject(firstImages[i].datum)
                    // elemKey = dayjs(firstImages[i], "YY-MM-DD_HH-mm.jpg") // dayjs
                    elemKey = new Date(date.year, date.month, date.day, date.hour, date.minute) // native date
                    
                    // add equal elements to tuple
                    elemTuple[i] = firstImages[i] // slower: elemTuple.push(firstImages[i])

                    // set elements to dummys
                    dummys[i] = firstImages[i] // slower: dummys.push(firstImages[i])
                } else {
                    if (dummys[i]) {
                        elemTuple[i] = dummys[i] // slower: elemTuple.push(dummys[i])
                    } else {
                        // TODO: display test image?
                        elemTuple[i] = null // slower: elemTuple.push(null)
                    }
                }
            }

            // add array of key and tuple to result
            list[listIndex] = [elemKey, elemTuple] // slower: list.push([elemKey, elemTuple])
            listIndex = listIndex + 1

            // create dictionary
            var index = list.length-1
            var tmp = dict
            // var indexes = [elemKey.$y, elemKey.$M, elemKey.$D, elemKey.$H, elemKey.$m] // dayjs
            var indexes = [date.year, date.month, date.day, date.hour, date.minute] // native date

            for (let i=0; i<indexes.length; i++) {
                const elem = indexes[i]
                if (!tmp[elem]) {
                    tmp[elem] = {}
                }
                tmp = tmp[elem]
            }

            tmp[indexes[indexes.length-1]] = index
        }
    }

    if (DEBUG) {
        console.timeEnd("generateTuples")
    }

    return [list, dict]
}

// parses YYYY-MM-DDTHH:mm:ss.000Z to { year: YYYY, month: MM, day: DD, hour: HH, minute: mm }
function databaseDateToObject(date) {
    return {
        year: date.substring(0,4),
        month: Number(date.substring(5,7))-1, // necessary for generating dictionary, e.g. 01 -> 1, 02 -> 2, 03 -> 3
        day: Number(date.substring(8,10)),
        hour: Number(date.substring(11,13)),
        minute: Number(date.substring(14,16))
    }
}

// parses YYYY-MM-DDTHH:mm:ss.000Z to YY-MM-DD_HH-mm.jpg
export function databaseDateToFilename(date) {
    return `${date?.substring(2,10)}_${date?.substring(11,16).replace(/:/, "-")}.jpg`
}

// parses YYYY-MM-DDTHH:mm:ss.000+00:01 to YYYY-MM-DDTHH:mm:ss.000Z
export function databaseDateToUtc(date) {
    return `${date?.substring(0,19)}.000Z`
}

// finds smallest date in given array
function findMin(array) {
    let min = array[0].datum
  
    for (let i = 1, len=array.length; i < len; i++) {
        let v = array[i].datum;
        min = (v < min) ? v : min;
    }
  
    return min;
}

// https://www.robinwieruch.de/react-custom-hook-check-if-overflow/
export const useIsOverflow = (ref, callback) => {
    const [isOverflow, setIsOverflow] = useState(undefined)
  
    useLayoutEffect(() => {
        const { current } = ref;
    
        const trigger = () => {
            const hasOverflow = current.scrollHeight > current.clientHeight
            setIsOverflow(hasOverflow)
            if (callback) {
                callback(hasOverflow)
            }
        }
    
        if (current) {
            trigger()
        }
    }, [callback, ref])
  
    return isOverflow
}

export async function sha256(message) {
    const msgBuffer = new TextEncoder().encode(message); // encode as UTF-8
    const hashBuffer = await window.crypto.subtle.digest('SHA-256', msgBuffer); // hash the message
    const hashArray = Array.from(new Uint8Array(hashBuffer)); // convert ArrayBuffer to Array
    const hashHex = hashArray.map((b) => b.toString(16).padStart(2, '0')).join(''); // convert bytes to hex string

    return hashHex;
}

// parses PT<HH>H<MM>M<SS>S to HH:mm
export function databaseTimeToString(time) {
    if (!time) {
        return ""
    }

    const regex = time.match(/PT(?:(\d+)H)?(?:(\d+)M)?(?:(\d+)S)?/)

    if (!regex) {
        return ""
    }

    const hours = parseInt(regex[1]) || 0
    const minutes = parseInt(regex[2]) || 0
    const formattedHours = hours.toString().padStart(2, '0')
    const formattedMinutes = minutes.toString().padStart(2, '0')
  
    return `${formattedHours}:${formattedMinutes}`;
}

// parses string to JSON, replaces single quotes with double quotes and None with null optionally
export function stringToJson(string, replaceSingleQuotes = true, replaceNoneByNull = true, replaceLineBreak = true) {
    try {
        let result = string

        if (replaceSingleQuotes) {
            result = string.replace(/'/g, '"')
        }

        if (replaceNoneByNull) {
            result = result.replace(/None/g, 'null')
        }

        if (replaceLineBreak) {
            result = result.replace(/(\r\n|\n|\r)/gm, " ")
        }

        return JSON.parse(result)
        
    } catch (error) {
        console.error(error)
        return null
    }
}

export const useStatistics = (component) => {

    const [isStatisticsLoaded, setIsStatisticsLoaded] = useState(false)
    
    const handleLoad = () => {
        setIsStatisticsLoaded(true)
    }

    const handleError = (error) => {
        console.error("[etracker] Failed to initialize:", error)
    }

    const getArea = (component) => {
        switch (component) {
            case 0:
                return "Home";
            case 1:
                return "Live";
            case 2:
                return "Archive";
            case 3:
                return "Timelapse";
            default:
                return "";
        }
    }

    useEffect(() => {
        const script = document.createElement('script');
        const params = document.createElement('script');
        let areaTextNode = document.createTextNode(`var et_pagename = "${CLIENT_ID}";`);

        if (component) {
            const area = getArea(component)
            areaTextNode = document.createTextNode(`var et_pagename = "${CLIENT_ID}"; var et_areas = "${area}";`);
        }

        script.setAttribute("id", "_etLoader");
        script.setAttribute("type", "text/javascript");
        script.setAttribute("charset", "UTF-8");
        script.setAttribute("data-block-cookies", "true");
        script.setAttribute("data-respect-dnt", "true");
        script.setAttribute("data-secure-code", "g9g1km");
        script.setAttribute("src", "//code.etracker.com/code/e.js");
        script.setAttribute("async", "");

        params.setAttribute("type", "text/javascript");
        params.appendChild(areaTextNode);

        document.head.appendChild(params);
        document.head.appendChild(script);

        script.addEventListener('load', handleLoad);
        script.addEventListener('error', handleError);

        return () => {
            script.removeEventListener('load', handleLoad);
            script.removeEventListener('error', handleError);
            document.head.removeChild(params);
            document.head.removeChild(script);
        }
    }, [component])

    return isStatisticsLoaded
}

export function normalizeWeatherCode(code) {
    return parseInt(code?.toString().slice(-2), 10) // 101 -> 1, ..., 116 -> 16
}

export function doesImageExist(url) {
    const img = new Image()
    img.src = url
    return new Promise((resolve) => {
        img.onload = () => resolve(true)
        img.onerror = () => resolve(false)
    })
}
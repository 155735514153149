import React from 'react';
import VideoJs from './VideoJs';
import Title from "../Title"
import { isMobile, isMobileOnly, useMobileOrientation } from 'react-device-detect';
import { useMediaQuery, useTheme } from '@mui/material'

const camCount = window.conf.CAMERAS.length

function Timelapse(props) {
    
    const { breakpoints } = useTheme();
    const { cameraId, contentHeight, videoSource, cameraName } = props
    const { isLandscape } = useMobileOrientation();
    
    const manyCams = camCount > window.conf.LAYOUT.WRAP_LIMIT
    const moreThanOneCamera = camCount > 1
    const isXl = useMediaQuery(breakpoints.up('xl'))
    const isMd = useMediaQuery(breakpoints.down('md'))

    return (
        <div 
            style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                height: 
                    isMobileOnly
                    ? isLandscape
                        ? contentHeight
                        : camCount < 2
                            ? contentHeight
                            : camCount < 3
                                ? contentHeight / 2
                                : 'unset'
                    : manyCams
                        ? "unset"
                        : isXl && camCount > 2
                            ? contentHeight/Math.ceil(camCount/3) - 8
                            : camCount === 1
                                ? contentHeight/Math.ceil(camCount/2)
                                : contentHeight/Math.ceil(camCount/2) - 8,
                width: manyCams ? "unset" : "100%",
                border: "none",
                padding: moreThanOneCamera && !isMobile && !isMd ? "0.2rem" : "0",
            }}
            className={
                !isMobileOnly
                ? camCount < 2
                    ? ''
                    : camCount === 2
                        ? 'height-2'
                        : 'height'
                : '' 
            }
        >
            <VideoJs
                source={videoSource}
                cameraName={cameraName}
                cameraId={cameraId}
            />
        </div>

    );
}

export default function Viewer(props) {
    return (
        <div 
            style={{
                display: "flex",
                alignContent: 'center',
                justifyContent: "center",
                position: "relative"
            }} 
            className={
                !isMobileOnly
                ? camCount < 2
                    ? ''
                    : camCount === 2
                        ? 'height-2'
                        : 'height'
                : '' 
            }
        >
            <Title cameraName={props.cameraName} />
            <Timelapse {...props} />
        </div>
    )
}
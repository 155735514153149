import dayjs from 'dayjs';
import { ChevronRight, ChevronLeft } from '@mui/icons-material';
import { Typography, Fade, IconButton, Box, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';

export default function TimeSelector({ footerOpen, stepLeft, timestamp, stepRight, date, loading, canStepLeft, canStepRight, setPickerOpen, inputRef }) {

    const { t } = useTranslation()
    
    var dateTime = timestamp ?? dayjs()
    var hourMinute = dayjs(dateTime).format("HH:mm").toString()
    var dayMonth = dayjs(dateTime).format(t("date.format.shorter")).toString()

    function handleClick() {
        if (setPickerOpen)
            setPickerOpen(true)
    }

    return (
        <Fade in={!footerOpen}>
            <Box sx={{ flexGrow: 1 }}>                        
                <IconButton 
                    sx={{ p: 0, pr: 1, pointerEvents: "all" }}
                    aria-label="stepleft"
                    onClick={stepLeft}
                    color="primary"
                    disabled={loading || !canStepLeft}
                >
                    <ChevronLeft 
                        sx={{ fontSize: date ? "3rem" : "2.4rem" }} 
                    />
                </IconButton>

                <Button 
                    component="span"
                    sx={{ 
                        verticalAlign: "middle", 
                        display: "inline-block", 
                        p: 0, 
                        color: "black", 
                        minWidth: 0 
                    }} 
                    onClick={handleClick}
                    ref={inputRef}
                    disabled={loading}
                >
                    {date
                    ? <>
                        <Typography className="TimeSelector-label">
                            {hourMinute}
                        </Typography>
                        <Typography className="TimeSelector-label">
                            {dayMonth}
                        </Typography>
                    </>
                    : <Typography 
                        className="TimeSelector-label" 
                        sx={{ 
                            fontSize: "1.6rem !important",
                            cursor: "pointer"
                        }}
                    >
                        {hourMinute}
                    </Typography>}
                    
                </Button>

                <IconButton 
                    sx={{ p: 0, pl: 1, pointerEvents: "all" }}
                    aria-label="stepright"
                    onClick={stepRight}
                    color="primary"
                    disabled={loading || !canStepRight}
                >
                    <ChevronRight 
                        sx={{ fontSize: date ? "3rem" : "2.4rem" }} 
                    />
                </IconButton>
            </Box>
        </Fade>
    )
}
import React, { useEffect, useRef, useState } from 'react';
import { Global } from '@emotion/react';
import { grey } from '@mui/material/colors';
import { Container, SwipeableDrawer, Box, Toolbar, Drawer } from '@mui/material';
import { isMobile, useMobileOrientation } from 'react-device-detect';
import { useDispatch, useSelector } from 'react-redux';
import { getData, setIndex, setInitialFooterHeight, setSource, setTimestamp } from '../../api/dataSlice';
import { useGetDataByCameraQuery } from '../../api/graphqlApi';
import Form from './Form';

export default function Controls({ initialSiderWidth, onOpenSideDrawer, setPaddingFooter }) {

    const { isPortrait } = useMobileOrientation();
    const { source, index } = useSelector(getData);
    const { data, isLoading: loading } = useGetDataByCameraQuery(null, { pollingInterval: 600000 });
    const { DEBUG } = window.conf;

    const [footerOpen, setFooterOpen] = useState(false);
    const [firstRun, setFirstRun] = useState(true);
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const [footerHeight, setFooterHeight] = useState('80vh');

    const dispatch = useDispatch();
    const drawerBleeding = 18;
    const footerWrapperRef = useRef(null);

    const toggleDrawer = (isOpen) => {
        setIsDrawerOpen(isOpen);
        onOpenSideDrawer(isOpen);
        if (isOpen) document.body.style.overflow = 'auto';
    };

    const toggleFooter = (isOpen) => {
        if (isOpen) {
            const vh80 = window.innerHeight * 0.8;
            // check content less 80vh and set footer height
            const contentHeight = footerWrapperRef.current?.offsetHeight;
            if (contentHeight < vh80) {
                setFooterHeight(`${contentHeight}px`);
            } else {
                setFooterHeight('80vh');
            }
        }
        setFooterOpen(isOpen);
    };

    const updateTimestamp = (timestamp) => {
        dispatch(setTimestamp(timestamp));
    };

    useEffect(() => {
        if (index) {
            updateTimestamp(source?.[0]?.[index]?.[0]);
        }
    }, [index]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (data) {
            if (firstRun) {
                var list = data[0];
                var lastIndex = list.length - 1;
                var lastElement = list[lastIndex];

                // set time in TimeSelector for display clock by date of last image (ONLY ONCE!)
                updateTimestamp(lastElement?.[0]);

                // set index in redux store for Controls
                dispatch(setIndex(lastIndex));

                setFirstRun(false);
            }

            // set source in redux store for Content
            dispatch(setSource(data));

            if (DEBUG) {
                console.log('source', data);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, dispatch]);

    useEffect(() => {
        if (isMobile) {
            dispatch(setInitialFooterHeight(16));
        } else {
            dispatch(setInitialFooterHeight(0));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isMobile]);

    // check footerWrapperRef resize
    useEffect(() => {
        const resizeObserver = new ResizeObserver((entries) => {
            for (let entry of entries) {
                const { height } = entry.contentRect;
                const outerHeight = height + drawerBleeding + 8;
                if (outerHeight < window.innerHeight * 0.8) {
                    setFooterHeight(`${outerHeight}px`);
                } else {
                    setFooterHeight('80vh');
                }
            }
        });
        if (footerWrapperRef.current) resizeObserver.observe(footerWrapperRef.current);
        return () => {
            resizeObserver.disconnect();
        };
    }, [footerOpen]);

    useEffect(() => {
        setPaddingFooter(drawerBleeding)
    }, [drawerBleeding, setPaddingFooter])

    // mobile footer portrait
    if (isMobile && isPortrait) {
        return (
            <Container sx={{ backgroundColor: 'black', height: '100%' }}>
                <Global
                    styles={{
                        body: {
                            backgroundColor: `${grey[100]} !important`,
                        },
                        '.MuiDrawer-root > .MuiPaper-root': {
                            height: footerHeight,
                            overflow: 'visible',
                        },
                    }}
                />
                <SwipeableDrawer
                    anchor="bottom"
                    open={footerOpen}
                    onClose={() => toggleFooter(false)}
                    onOpen={() => toggleFooter(true)}
                    swipeAreaWidth={drawerBleeding + 9}
                    disableSwipeToOpen={false}
                    ModalProps={{
                        keepMounted: true,
                    }}
                >
                    <Box
                        sx={{
                            position: 'absolute',
                            top: -drawerBleeding,
                            borderTopLeftRadius: 8,
                            borderTopRightRadius: 8,
                            visibility: 'visible',
                            right: 0,
                            left: 0,
                            backgroundColor: '#fff',
                        }}
                    >
                        <Box
                            sx={{
                                width: 30,
                                height: 6,
                                backgroundColor: footerOpen ? '#e0e0e0' : '#003366',
                                borderRadius: '3px',
                                position: 'absolute',
                                top: 8,
                                left: 'calc(50% - 15px)',
                            }}
                        />
                        <Box
                            sx={{
                                maxHeight: `calc(80vh - ${drawerBleeding + 8}px)`,
                                overflowY: 'auto',
                                width: 'auto',
                                overflowX: 'hidden',
                                p: 2,
                                mt: 2,
                            }}
                            ref={footerWrapperRef}
                        >
                            <Form initialSiderWidth={initialSiderWidth} loading={loading}/>
                        </Box>
                    </Box>
                </SwipeableDrawer>
            </Container>
        );
    // mobile sider landscape
    } else if (isMobile && !isPortrait) {
        return (
            <>
                <Global
                    styles={{
                        '.MuiDrawer-root > .MuiPaper-root': {
                            height: `100%`,
                            overflow: 'visible',
                        },
                    }}
                />

                <SwipeableDrawer
                    anchor="right"
                    open={isDrawerOpen}
                    onClose={() => toggleDrawer(false)}
                    onOpen={() => toggleDrawer(true)}
                    swipeAreaWidth={drawerBleeding + 9}
                    disableSwipeToOpen={false}
                    disableDiscovery={false}
                    hideBackdrop={true}
                    ModalProps={{
                        keepMounted: true,
                    }}
                    sx={{
                        width: 0,
                        height: '100%',
                        flexShrink: 0,
                        [`& .MuiDrawer-paper`]: {
                            width: initialSiderWidth - drawerBleeding,
                            boxSizing: 'border-box',
                        },
                    }}
                >
                    <Box
                        sx={{
                            backgroundColor: grey[100],
                            position: 'absolute',
                            height: '100%',
                            top: 0,
                            right: 0,
                            left: -drawerBleeding,
                            visibility: 'visible',
                            overflow: 'auto',
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            overflowX: 'hidden',
                        }}
                    >
                        <Box
                            sx={{
                                position: 'absolute',
                                backgroundColor: isDrawerOpen ? '#e0e0e0' : '#003366',
                                height: 30,
                                width: 6,
                                top: `calc(50% + 16px)`,
                                left: 8,
                                borderRadius: 3,
                            }}
                        />
                        <Box
                            sx={{
                                overflow: 'auto',
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                overflowX: 'hidden',
                                p: 2,
                                pl: 1,
                                pb: 0,
                                mt: '64px',
                                ml: `${drawerBleeding}px`,
                                height: '100%',
                                maxHeight: 'calc(100vh - 94px)',
                            }}
                        >
                            <Form initialSiderWidth={initialSiderWidth} loading={loading} />
                        </Box>
                    </Box>
                </SwipeableDrawer>
            </>
        );
    // desktop side
    } else {
        return (
            <Drawer
                variant="permanent"
                anchor="right"
                sx={{
                    width: initialSiderWidth,
                    flexShrink: 0,
                    [`& .MuiDrawer-paper`]: {
                        width: initialSiderWidth,
                        boxSizing: 'border-box',
                    },
                }}
            >
                <Toolbar /> {/* provides top distance without additional functionality */}
                <Box
                    sx={{
                        overflow: 'auto',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'start',
                        overflowX: 'hidden',
                        p: 2,
                        height: '100%',
                        maxHeight: 'calc(100vh - 64px)',
                    }}
                >
                    <Form initialSiderWidth={initialSiderWidth} loading={loading} />
                </Box>
            </Drawer>
        );
    }
}

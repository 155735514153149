import React, { useEffect, useState, useRef } from 'react';
import { Box, CircularProgress, LinearProgress, Typography, useMediaQuery, useTheme } from '@mui/material'
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import { isMobile, isMobileOnly, useMobileOrientation } from 'react-device-detect';
import { isTouchscreen, saveFile } from '../Utilities';
import { useDispatch, useSelector } from 'react-redux';
import { loadNextImage, loadPrevImage, setIndex, setOverviewCameraId, getData, setCheckedArchivePictures } from '../../api/dataSlice';
import { useSwipeable } from 'react-swipeable';
import { useTranslation } from 'react-i18next';
import dayjs from "dayjs"
import PrismaZoom from 'react-prismazoom'
import Title from "../Title"
import Controlbar from "../Controlbar"
import Image from "../Image"
import OverviewGrid from './OverviewGrid';

let imgHeight = 0;

export default function Viewer(props) {

    const { breakpoints } = useTheme();
    const { t } = useTranslation()
    const { isLandscape } = useMobileOrientation()
    const { isDrawerOpen, contentHeight, cameraName, cameraId, cameraUrl, imageSource, imageLoading, imagesList, imageIndex } = props
    const { timestamp } = useSelector(getData)
    const { CAMERAS, LAYOUT: { WRAP_LIMIT } } = window.conf

    const [zoom, setZoom] = useState(1)
    const [imageError, setImageError] = useState(false)
    const [showOverview, setShowOverview] = useState(false);
    const [isDownloading, setIsDownloading] = useState(false);

    const fullscreenHandle = useFullScreenHandle()
    const zoomRef = useRef()
    const zoomInFullscreenOnly = isTouchscreen && isMobile
    const camCount = CAMERAS.length
    const manyCams = camCount > WRAP_LIMIT
    const dispatch = useDispatch();
    const imgRef = useRef(null)
    const isXl = useMediaQuery(breakpoints.up('xl'))

    useEffect(() => {
        resetZoom()
        return () => resetZoom()
    }, [])

    function onZoomChange(zoom) {
        if (zoom < 1) {
            handleOverviewClick()
        } else {
            setZoom(zoom)
        }
    }

    function onFullscreenChange() {
        resetZoom()
    }

    function resetZoom() {
        zoomRef?.current?.reset()
        setZoom(1)
    }

    const handleOverviewClick = () => {
        resetZoom();
        if (!showOverview) {
            imgHeight = imgRef.current.parentNode.offsetHeight
            setShowOverview(true);
            dispatch(setOverviewCameraId(cameraId));
        } else {
            setShowOverview(false);
            dispatch(setOverviewCameraId(null));
            dispatch(setCheckedArchivePictures({ type: 'clean' }))
        }
    }

    function handleTakeSnapshot() {
        setIsDownloading(true);
        fetch(imageSource)
        .then(res => res.ok ? res.blob() : console.error(res.status, res.statusText))  
        .then(blob => saveFile(blob, (cameraName ? (cameraName + "_") : "") + dayjs(timestamp).format("YY-MM-DD_HH-mm") + ".jpg"))
        .catch(error => console.error(error))
        .finally(() => setIsDownloading(false))
    }

    const containerStyle = {
        style: {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: fullscreenHandle.active
                ? "100%"
                : isMobileOnly
                    ? isLandscape
                        ? contentHeight
                        : camCount < 2
                            ? contentHeight
                            : camCount < 3
                                ? contentHeight/2
                                : 'unset'
                    : manyCams
                        ? "99.5%"
                        : isXl && camCount > 2
                            ? contentHeight/Math.ceil(camCount/3)
                            : contentHeight/Math.ceil(camCount/2)
        }
    }

    // Set use selected image index to the redux store to set the current camera imagea to the given index
    function onOverviewImageSelected(index, hideOverview) {
        // Get index of the user selected image
        if (Number.isFinite(index)) {
            dispatch(setIndex(index));
        }

        if (hideOverview) {
            setShowOverview(false);
            dispatch(setCheckedArchivePictures({ type: 'clean' }))
        }
    }
    
    const handlers = useSwipeable({
        onSwipedLeft: () => zoom === 1 ? dispatch(loadNextImage()) : null,
        onSwipedRight: () => zoom === 1 ? dispatch(loadPrevImage()) : null,
        swipeDuration: 500,
        preventScrollOnSwipe: true,
        trackMouse: true
    });

    const renderCameraImage = () => {
        return (
            <div {...handlers}>
                {zoomInFullscreenOnly
                    ? fullscreenHandle.active
                        ? <PrismaZoom
                            allowPan={!imageLoading && !imageError}
                            allowZoom={!imageLoading && !imageError}
                            ref={zoomRef}
                            onZoomChange={onZoomChange}
                            minZoom={0.75}
                            {...containerStyle}
                            className= {!isMobile
                                            ? camCount < 2
                                                ? ''
                                                : camCount === 2
                                                    ? 'height-2'
                                                    : 'height'
                                            : isLandscape && isDrawerOpen && camCount > 1
                                                ? 'arch-mbl-height'
                                                : '' }
                        >
                            <Image
                                ref={imgRef}
                                source={imageSource}
                                cursor={zoom > 1 ? 'move' : 'default'}
                                loading={imageLoading}
                                setImageError={setImageError}
                            />
                            {imageLoading ? <CircularProgress sx={{ position: 'absolute' }} disableShrink size={60} color="secondary" /> : null}

                        </PrismaZoom>
                        : <Box 
                            {...containerStyle} 
                            className={
                                !isMobile
                                ? camCount < 2
                                    ? ''
                                    : camCount === 2
                                        ? 'height-2'
                                        : 'height'
                                : isLandscape && isDrawerOpen && camCount > 1
                                    ? 'arch-mbl-height'
                                    : '' 
                            }
                        >
                            <Image
                                ref={imgRef}
                                source={imageSource}
                                cursor={zoom > 1 ? 'move' : 'default'}
                                loading={imageLoading}
                                setImageError={setImageError}
                            />
                            {imageLoading ? <CircularProgress sx={{ position: 'absolute' }} disableShrink size={60} color="secondary" /> : null}
                        </Box>
                    : <PrismaZoom
                        allowPan={!imageLoading && !imageError}
                        allowZoom={!imageLoading && !imageError}
                        ref={zoomRef}
                        onZoomChange={onZoomChange}
                        minZoom={0.75}
                        {...containerStyle}
                        className={
                            !isMobile
                            ? camCount < 2
                                ? ''
                                : camCount === 2
                                    ? 'height-2'
                                    : 'height'
                            : isLandscape && isDrawerOpen && camCount > 1
                                ? 'arch-mbl-height'
                                : ''
                        }
                    >
                        <Image
                            ref={imgRef}
                            source={imageSource}
                            cursor={zoom > 1 ? 'move' : 'default'}
                            loading={imageLoading}
                            setImageError={setImageError}
                        />

                        {imageLoading ? <CircularProgress sx={{ position: 'absolute' }} disableShrink size={60} color="secondary" /> : null}

                    </PrismaZoom>}
            </div>
        )
    }

    const Progress = () => {
        return (
            <div style={{
                position: 'absolute',
                width: '99%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                bottom: 35
            }}>
                <Typography variant="caption" fontSize={16} color="white">
                    {t("actions.downloading")}...
                </Typography>
                <LinearProgress style={{ width: '100%' }} />
            </div>
        )
    }
    
    return (
        <FullScreen 
            handle={fullscreenHandle}
            onChange={onFullscreenChange}
        >
            <Title cameraName={cameraName} />

            <Box display={showOverview ? 'none' : 'block'}>
                {renderCameraImage()}
            </Box>

            {showOverview && 
            <OverviewGrid 
                imgHeight={imgHeight}
                imagesList={imagesList}
                imageIndex={imageIndex}
                contentHeight={contentHeight}
                cameraId={cameraId}
                cameraUrl={cameraUrl}
                fullscreenHandle={fullscreenHandle}
                onImageSelected={onOverviewImageSelected}
                showOverview={showOverview}
                handleOverviewClick={handleOverviewClick}
            />}

            <Controlbar
                cameraName={cameraName}
                currentZoom={zoom}
                fullscreenHandle={fullscreenHandle}
                zoomRef={zoomRef}
                zoomInFullscreenOnly={zoomInFullscreenOnly}
                disabled={!imageSource || imageLoading || imageError}
                loading={imageLoading}
                imageSource={imageSource}
                showOverview={showOverview}
                handleOverviewClick={handleOverviewClick}
                hasOverview
                handleTakeSnapshot={handleTakeSnapshot}
            />

            {isDownloading && <Progress />}
        </FullScreen>
    )
}
import dayjs from "dayjs"
import { Box, CircularProgress, Typography } from "@mui/material"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { CartesianGrid, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts"
import { useGetVisitsGraphDataQuery } from "../../api/statsApi"

export default function TotalVisitsChart ({ args }) {

    const { t } = useTranslation()
    const [dataForChart, setDataForChart] = useState(null)
    const { data, isFetching: loading, error } = useGetVisitsGraphDataQuery(args)
    
    useEffect(() => {
        if (data) {
            setDataForChart(data.filter(el => el.utm_date).map(el => ({
                "date": el.utm_date,
                "visits": el.unique_visits
            })))
        }
    }, [data])

    if (error) {
        return (
            <Box sx={{ pb: 2 }}>
                <Typography sx={{ fontSize: "1rem", color: "#003366" }}>
                    {t("msg.failed")}
                </Typography>
            </Box>
        )
    } else {
        if (loading) {
            return (
                <Box sx={{ display: 'flex', justifyContent: 'center', p: 2, pb: 4 }}>
                    <CircularProgress />
                </Box>
            )
        } else {
            return (
                <ResponsiveContainer width="100%" height={200}>
                    <LineChart 
                        data={dataForChart} 
                        margin={{ 
                            top: 12, 
                            left: -22, 
                            right: 40,
                            bottom: 8
                        }}
                    >
                        <Line 
                            type="monotone" 
                            dataKey="visits"
                            stroke="#003366" 
                        />
                        <XAxis 
                            dataKey="date" 
                            tickFormatter={date => dayjs(date).format(t("date.format.shorter"))}/>
                        <YAxis 
                            dataKey="visits" 
                            // tickFormatter={num => `${num} `}
                        />
                        <Tooltip labelFormatter={(label, payload) => {
                            if (payload[0]?.name) {
                                payload[0].name = t("stats.visits")
                            }
                            return dayjs(label).format(t("date.format.short"))
                        }} />
                        <CartesianGrid opacity={0.5} vertical={false} />
                    </LineChart>
                </ResponsiveContainer>
            )
        }
    }
}
import { LinearProgress, Box, Typography, CircularProgress } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

export default function BarChart ({ title, data, loading, error, selectedKey }) {

    const { t } = useTranslation()
    const [maxValue, setMaxValue] = useState(0)
    const [dataForChart, setDataForChart] = useState([])
   
    useEffect(() => {
        if (data) {
            let objectsArray = []
            let max = 0
            // set data in data for chart
            for (let i = 1; i <= data.length - 1; i++) {
                objectsArray.push({
                    "key": i,
                    "name": data[i][selectedKey] === "-" ? "Unknown" : data[i][selectedKey],
                    "value": data[i].unique_visits
                })
                if (max < data[i].unique_visits) {
                    max = data[i].unique_visits
                }
            }
            setMaxValue(max)
            setDataForChart(objectsArray)
        }
    }, [data, selectedKey])

    if (error) {
        return (
            <Box sx={{ width: '100%', display: 'flex' }}>
                <Typography sx={{ fontSize: "1rem", color: "#003366", display: 'flex', alignItems: "center" }}>
                    {t("msg.failed")}
                </Typography>
            </Box>
        )
    } else {
        if (loading) {
            return (
                <Box sx={{ display: 'flex', justifyContent: 'center', p: 2, width: '100%' }}>
                    <CircularProgress />
                </Box>
            )
        } else {
            return (
                <Box sx={{ width: '100%' }}>
                    <Typography sx={{ fontSize: "1rem", color: "#003366", mb: 1 }}>
                        {title}
                    </Typography>
                    {dataForChart.map(data => 
                        <LinearProgressWithLabel 
                            key={data.key} 
                            value={data.value} 
                            name={data.name} 
                            maxValue={maxValue} 
                        />
                    )}
                </Box>
            )
        }
    }
}

function LinearProgressWithLabel({ name, value, maxValue }) {
    return (
        <>
            <Typography sx={{ textAlign: 'left', fontSize: "0.8rem" }}>
                {name}
            </Typography>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Box sx={{ width: '100%' }}>
                    <LinearProgress variant="determinate" value={(value/maxValue)*100} />
                </Box>
                <Box sx={{ minWidth: 35 }}>
                    <Typography variant="body2" color="text.secondary" sx={{ textAlign: "right" }}>
                        {value}
                    </Typography>
                </Box>
            </Box>
        </>
    )
}